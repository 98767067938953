const MESSAGE_VARIANT = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const TAB = {
  GREENH2: "GreenH2",
  ABOUT: "About"
}

export {
  MESSAGE_VARIANT,
  TAB,
}