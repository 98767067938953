import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "../hooks/privateRoute";
import PublicRoute from "../hooks/public-route";
// import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import WalletAddress from '../pages/walletAddress';
import GreenH2Nft from '../pages/greenH2nft';
import About from "../pages/about";

const RoutesPage = () => {

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route exact path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/:pageType" element={<Navigate to="/dashboard" />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/wallet-address" element={<WalletAddress />} />
        <Route path="/greenH2Nft" element={<GreenH2Nft />} />
        <Route path="/about" element={<About />} />
      </Route>
    </Routes>
  )
}

export default RoutesPage;