/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAPI, userDashboardAPI, getInvestmentDetail } from "../../nft/services";
import { setDashboardData, setUserDashboardData } from "../../nft/redux/nftAction";
import { historyResponseSet } from "../../../utils/token";

const DashboardSummary = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [myToken, setMyToken] = useState(0);
  const { selectedCrypto, greenH2SelectedCrypto, userDashboard } = useSelector((state) => state.nft);
  const { currentPage } = useSelector((state) => state.pagination);

  const data = {
    token_id: selectedCrypto,
  };

  const getData = async () => {
    const res = await dashboardAPI(data);
    if (res.status) {
      setValue(res.dashboard_data);
      const val = {
        dashboard: res.dashboard_data,
        offSet: res.utc_offset,
      };
      if (userDashboard?.dashboard_data.snt_tokens !== res.dashboard_data.snt_tokens) {
        dispatch(setDashboardData(val));
      }
    }
  };

  const userData = async () => {
    const res = await userDashboardAPI(data);
    if (res.status) {
      const getToken = await getInvestmentDetail();
      if (getToken) {
        const investmentProjectWise = await historyResponseSet(getToken?.investment);
        const token = investmentProjectWise?.length ? investmentProjectWise.filter((ele) => ele?.project_id === selectedCrypto) : 0
        setMyToken(token[0]?.purchased_token);
      }
      dispatch(setUserDashboardData(res));
    }
  };

  useEffect(() => {
    getData();
    userData();
  }, [selectedCrypto, greenH2SelectedCrypto, currentPage]);

  return (
    <>
      {value !== undefined && value !== null && (
        <div className="row counter-items mb-4 mb-lg-5">
          <div className="col-12 col-md-6 col-lg-3 counter-item mb-4">
            <div className="counter-item-content">
              <h3 style={{ color: "black" }}>{value?.snt_market_cap.toLocaleString()}</h3>
              <p className="mb-0">Market Cap</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 counter-item mb-4">
            <div className="counter-item-content">
              <h3 style={{ color: "black" }}>{value?.snt_total_circulating_supply.toLocaleString()}</h3>
              <p className="mb-0">Total Circulating Supply</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 counter-item mb-4">
            <div className="counter-item-content">
              <h3 style={{ color: "black" }}>${value?.snt_price}</h3>
              <p className="mb-0">Price</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 counter-item mb-4">
            <div className="counter-item-content">
              <h3 style={{ color: "black" }}>{myToken ? myToken : 0}</h3>
              <p className="mb-0">My Token</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardSummary;
