/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../layout/header";
import SideBar from "../layout/side-bar";
import Footer from "../layout/footer";
import PrivateFooter from "../layout/footer/private-footer";

const ThemeContainer = ({ children }) => {
  const location = useLocation();
  const { headerList } = useSelector((state) => state.home);
  const pathname = location.pathname.split('/')[1]
  const isInArray = headerList?.filter((ele) => ele.page_type_name === pathname);

  let pageName;
  if (location.pathname.includes("greenH2")) {
    pageName = "greenH2";
  } else if (location.pathname.includes("nature")) {
    pageName = "nature";
  } else {
    pageName = "corporate";
  }

  return (
    <Fragment>
      {
        isInArray && (isInArray?.length !== 0 || location?.pathname === "/") ?
          <Fragment>
            <div className="">
              <Header page_type={"public"} />
              <Fragment>
                {children}
              </Fragment>
              <Footer pageName={pageName} />
            </div>
          </Fragment>
          :
          <div className="">
            <Header page_type={"private"} />
            <Fragment>
              <div className="main-content position-relative d-block">
                <div className="row mt-lg-5">
                  <div className="col-lg-1 col-md-3 col-sm-1" style={{ minWidth: "151px" }}>
                    <SideBar />
                  </div>
                  <div className="col-lg-10 col-md-9 col-sm-11 mt-md-5">
                    <div className="wrapper w-100 float-start">
                      <div className="page-content w-100 float-start position-relative">
                        <div className="content-wrapper service-nft">
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
            <PrivateFooter />
          </div>
      }
    </Fragment>
  );
};

export default ThemeContainer;
